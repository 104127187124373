@font-face {
    font-family: 'SNPro';
    src: url('./assets/fonts/SNPro/SNPro-Regular.woff2') format('woff2'),
    url('./assets/fonts/SNPro/SNPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SNPro';
    src: url('./assets/fonts/SNPro/SNPro-Bold.woff2') format('woff2'),
    url('./assets/fonts/SNPro/SNPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    font-family: 'SNPro', system-ui, sans-serif;
    background-color: #FAFFFB;
}
